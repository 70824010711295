<template>
  <div class="min-h-screen flex flex-col">
    <Header class="pt-1 pb-2 sm:pb-3 ">
      <template #logowithtext>
        <LogoWithText :large="true"/>
      </template>
    </Header>
    <main class="container mx-auto grow max-w-8xl">
      <slot/>
    </main>
    <Footer/>
  </div>
</template>
